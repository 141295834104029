<template>
    <div>
        <card-header title="Payment Plan" icon="fa-credit-card-front"/>
        <card-toolbar>
            <button @click="$openCard('make-payment', {planId: plan.id, regId: props.regId}, card)"><i class="fas fa-credit-card mr-2"/>Make Payment</button>
            <button @click="$openCard('edit-payment-plan', {planId: plan.id, regId: props.regId}, card)"><i class="fas fa-pencil mr-2"/>Edit Plan/Task</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <subheader-list-item title="Plan Details" icon="fa-list"/>

                <container-list-item>
                    <data-row title="Total">{{plan.total | currency}}</data-row>
                    <data-row title="Deposit">{{plan.down | currency}}</data-row>
                    <data-row title="Paid">{{plan.paid | currency}}</data-row>
                    <data-row title="Remaining">{{(plan.total - plan.paid) | currency}}</data-row>
                    <data-row title="Expected">{{expected | currency}}</data-row>
                    <data-row title="Past Due">{{pastDue | currency}}</data-row>
                </container-list-item>

                <subheader-list-item title="Payment Method" icon="fa-credit-card"/>

                <payment-method-list-item
                    :card="card"
                    v-for="m in plan.methods"
                    :method="m"
                    @click="$openCard('edit-payment-method', {planId: plan.id, methodId: m.id}, card)"
                    :active="child && child.definition.component === 'edit-payment-method' && child.props.methodId === m.id.toString()"
                />

                <card-list-item v-if="plan.methods.length === 0">
                    <template v-slot:icon>
                        <i class="fas fa-fw fa-exclamation-triangle has-text-warning"></i>
                    </template>
                    <template v-slot:content>
                        <div class="is-italic has-text-warning is-size-6">No payment method on file.</div>
                    </template>
                </card-list-item>

                <navigation-list-item
                    title="Add New Payment Method"
                    icon="fa-plus-circle"
                    @click="$openCard('new-payment-method', {planId: plan.id, regId: props.regId}, card)"
                />

                <separator-list-item/>

                <navigation-list-item
                    title="Notes"
                    icon="fa-sticky-note"
                    :active="child && child.definition.component === 'reg-notes'"
                    :badge="registration.notes.length"
                    @click="$openCard('reg-notes', {registrationId: registration.id}, card)"
                />
                <navigation-list-item
                    title="Itemized Registration"
                    icon="fa-file-invoice"
                    @click="$openCard('itemized', {regId: registration.id}, card)"
                />
                <task-navigation-list-item
                    title="Add-ons"
                    icon="fa-layer-plus"
                    :active="child && child.definition.component === 'addons'"
                    @click="$openCard('addons', {tripId: registration.trip_id, registrationId: registration.id}, card)"
                    :badge="registration.addons.length"
                    :tasks="registration.tasks.addons"
                />

                <subheader-list-item title="Plan Items" icon="fa-layer-group"/>

                <container-list-item>
                    <data-row title="Total">{{itemsTotal | currency}}<i class="fas fa-exclamation-triangle has-text-warning ml-2" v-if="Math.abs(plan.total - itemsTotal) > 0.10"></i></data-row>
                    <data-row title="Paid">{{itemsPaid | currency}}<i class="fas fa-exclamation-triangle has-text-warning ml-2" v-if="Math.abs(plan.paid - itemsPaid) > 0.10"></i></data-row>
                    <data-row title="Remaining">{{itemsRemaining | currency}}<i class="fas fa-exclamation-triangle has-text-warning ml-2" v-if="Math.abs((plan.total - plan.paid) - itemsRemaining) > 0.10"></i></data-row>
                </container-list-item>

                <group-header-list-item title="Past Items" :badge="pastCount" :open="showPastItems" @click="showPastItems=!showPastItems"/>

                <plan-item-list-item
                    :card="card"
                    :item="i"
                    v-for="i in plan.items"
                    v-if="showPastItems && i.past"
                    @click="$openCard('edit-plan-item', {planId: plan.id, itemId: i.id}, card)"
                    :active="child && child.definition.component === 'edit-plan-item' && child.props.itemId === i.id.toString()"
                />

                <group-header-list-item title="Future Items" :badge="futureCount" :open="showFutureItems" @click="showFutureItems=!showFutureItems"/>

                <plan-item-list-item
                    :card="card"
                    :item="i"
                    v-for="i in plan.items"
                    v-if="showFutureItems && !i.past"
                    @click="$openCard('edit-plan-item', {planId: plan.id, itemId: i.id}, card)"
                    :active="child && child.definition.component === 'edit-plan-item' && child.props.itemId === i.id.toString()"
                />

                <navigation-list-item
                    title="Add New Plan Item"
                    icon="fa-plus-circle"
                    @click="$openCard('new-plan-item', {planId: plan.id, regId: props.regId}, card)"
                />

                <subheader-list-item title="Transactions" icon="fa-receipt"/>
                <group-header-list-item title="Transactions" :badge="plan.transactions.length" :open="showTransactions" @click="showTransactions=!showTransactions"/>

                <transaction-list-item
                    :card="card" v-for="t in plan.transactions"
                    :transaction="t"
                    v-if="showTransactions"
                    @click="$openCard('edit-transaction', {planId: plan.id, regId: props.regId, transactionId: t.id}, card)"
                    :active="child && child.definition.component === 'edit-transaction' && child.props.transactionId === t.id.toString()"
                />

                <navigation-list-item
                    title="Add New Transaction"
                    icon="fa-plus-circle"
                    @click="$openCard('new-transaction', {planId: plan.id, regId: props.regId}, card)"
                />

            </card-list>
        </card-body>
    </div>
</template>

<script>
    import moment from "moment";
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import NavigationListItem from "../../TIER/components/NavigationListItem";

    import {client as http} from '../../http_client';
    import CardBody from "../../TIER/components/CardBody";
    import DataRow from "../../TIER/components/DataRow";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import TransactionListItem from "../../components/TransactionListItem";
    import PaymentMethodListItem from "../../components/PaymentMethodListItem";
    import PlanItemListItem from "../../components/PlanItemListItem";
    import TaskNavigationListItem from "../../components/TaskNavigationListItem";
    import CardListItem from "../../TIER/components/CardListItem";
    import CardFooter from "../../TIER/components/CardFooter";
    import GroupHeaderListItem from "../../TIER/components/GroupHeaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    export default {
        props: ['card', 'child', 'props'],
        components: {ContainerListItem, GroupHeaderListItem, CardFooter, CardListItem, TaskNavigationListItem, PlanItemListItem, PaymentMethodListItem, TransactionListItem, SubheaderListItem, DataRow, CardBody, NavigationListItem, SeparatorListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                registration: {
                    trip: {},
                    user: {},
                    tasks: {},
                    addons: [],
                    flights: [],
                    notes: []
                },
                plan: {
                    total: 0,
                    paid: 0,
                    items: [],
                    methods: [],
                    transactions: []
                },
                showPastItems: false,
                showFutureItems: true,
                showTransactions: false
            };
        },
        computed: {
            pastCount: function() {
                let total = 0;
                if (this.plan.items.length) {
                    for (let i = 0; i < this.plan.items.length; i++) {
                        const item = this.plan.items[i];
                        if (item.past) total++;
                    }
                }
                return total;
            },
            futureCount: function() {
                let total = 0;
                if (this.plan.items.length) {
                    for (let i = 0; i < this.plan.items.length; i++) {
                        const item = this.plan.items[i];
                        if (!item.past) total++;
                    }
                }
                return total;
            },
            expected: function() {
                let total = 0;
                if (this.plan.items.length) {
                    for (let i = 0; i < this.plan.items.length; i++) {
                        const item = this.plan.items[i];
                        if (item.past) total += (item.amount * item.quantity)
                    }
                }
                return total;
            },
            pastDue: function() {
                return this.expected - this.plan.paid
            },
            itemsTotal: function() {
                let total = 0;
                for (let i = 0; i < this.plan.items.length; i++) {
                    const item = this.plan.items[i];
                    total += (item.amount * item.quantity)
                }
                return total;
            },
            itemsPaid: function() {
                let total = 0;
                for (let i = 0; i < this.plan.items.length; i++) {
                    const item = this.plan.items[i];
                    if (item.paid === 1) total += (item.amount * item.quantity)
                }
                return total;
            },
            itemsRemaining: function() {
                let total = 0;
                for (let i = 0; i < this.plan.items.length; i++) {
                    const item = this.plan.items[i];
                    if (item.paid === 0) total += (item.amount * item.quantity)
                }
                return total;
            }
        },
        methods: {
            loadPlan: function(force) {
                this.$emit('loading', true);
                http.get('/api/registrations/' + this.props.regId, {force}).then(response => {
                    this.registration = response.data;
                    this.$emit('title', this.registration.user.first_name + ' ' + this.registration.user.last_name);

                    http.get('/api/payment_plans/' + this.props.planId, {force}).then(response => {
                        for (let i = 0; i < response.data.items.length; i++) {
                            const item = response.data.items[i];
                            item.past = (moment(item.due_on).isBefore(moment().subtract(1, 'day')));
                        }

                        this.plan = response.data;
                        this.plan.remaining = response.total - response.paid;
                        this.$emit('loading', false);
                    });
                });
            },
            reload: function() {
                this.loadPlan(true);
            }
        },
        watch: {
            'props.planId': function() {
                this.loadPlan(true);
            }
        },
        mounted() {
            this.loadPlan(true);
        }
    };
</script>
