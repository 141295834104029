<template>
    <li @click="toggle">
        <div class="columns">
            <div class="column" style="padding-left: 0.8rem">
                <div class="group-title">
                    <i class="fas fa-fw" :class="{'fa-caret-right': !open, 'fa-caret-down': open}"></i>{{title}}
                </div>
            </div>
            <div class="column has-text-right" v-if="badge">
                <div class="group-badge">{{badge}}</div>
            </div>
        </div>
    </li>
</template>

<script>
    import CardListItem from "./CardListItem";
    export default {
        name: 'GroupHeaderListItem',
        components: {CardListItem},
        props: {
            title:  String,
            badge: [String, Number],
            open: Boolean
        },
        methods: {
            toggle: function() {
                this.$emit('click');
            }
        }
    }
</script>

<style lang="scss" scoped>
    $bg: lighten(desaturate($t-primary, 35), 10);

    li {
        background-color: $bg !important;
        border-bottom: 1px solid $bg !important;
        padding: 0.3rem 0 0.2rem;
        margin: 2px 0;

        cursor: pointer;

        &:hover {
            background-color: darken($bg, 5) !important;
        }

        .group-title {
            font-family: 'Montserrat', sans-serif;
            color: white;
            text-transform: uppercase;
            font-size: 0.7rem;
            font-weight: bold;
            letter-spacing: 0.5px;
            padding: 0;
            margin: 0;

            i {
                font-size: 0.9rem;
            }
        }

        .group-badge {
            font-family: 'Montserrat', sans-serif;
            color: white;
            text-transform: uppercase;
            font-size: 0.7rem;
            font-weight: bold;
            padding: 0 0.9rem 0 0;
            margin: 0;
        }
    }
</style>
