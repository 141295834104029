<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-receipt" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="tile-title">{{transaction.amount | currency}}</div>
            <div class="meta"><i class="far fa-fw fa-align-left mr-2"></i>{{transaction.description}}</div>
            <div class="meta"><i class="far fa-fw fa-calendar mr-2"></i>{{transaction.created_at | moment('MMMM Do, YYYY h:mm a')}}</div>
        </template>
        <template v-slot:status>
            <div v-if="transaction.result==1"><i class="fas fa-check"></i></div>
            <div v-else><i class="fas fa-ban"></i></div>
        </template>
        <template v-slot:details>
            <div class="details-rollout">
                <div class="data mt-1">
                    <data-row mini title="Method">{{transaction.cc}}</data-row>
                    <data-row mini title="Exp">{{transaction.exp}}</data-row>
                    <data-row mini title="ID">{{transaction.id}}</data-row>
                    <data-row mini title="Raw">{{transaction.raw_result}}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'TransactionListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            transaction: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            },
            showUser: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false
            }
        }
    }
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 10);

        .tile-title {
            font-size: .9rem;
            font-weight: 700;
        }

        padding: 0.5rem 0;

        .details-rollout {
            margin-top: 0.5rem;
        }

        .ban {
            color: #f9b000 !important;
        }
    }

    .drag-handle {
        cursor: ns-resize;
    }
</style>
