<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-credit-card" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="tile-title">Card ending in {{method.last}}</div>
            <div class="meta"><i class="far fa-fw fa-history mr-2"></i>Expires {{expires | moment('MMMM, YYYY')}}</div>
        </template>
        <template v-slot:status>

        </template>
        <template v-slot:details>
            <div class="details-rollout">
                <div class="data mt-1">
                    <data-row mini title="Type">Credit Card</data-row>
                    <data-row mini title="Added">{{method.created_at | moment('MMMM Do, YYYY h:mm a')}}</data-row>
<!--                    <data-row mini title="Updated" v-if="method.updated_at">{{method.updated_at | moment('MMMM Do, YYYY h:mm a')}}</data-row>-->
                    <data-row mini title="Auth.net">{{method.authnet_payment_profile_id}}</data-row>
                    <data-row mini title="ID">{{method.id}}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'PaymentMethodListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            method: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            },
            showUser: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false
            }
        },
        computed: {
            expires: function() {
                return new Date(this.method.expiration.substr(2,4), this.method.expiration.substr(0,2)-1)
            }
        }
    }
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 10);

        .tile-title {
            font-size: .9rem;
            font-weight: 700;
        }

        padding: 0.5rem 0;

        .details-rollout {
            margin-top: 0.5rem;
        }

        .ban {
            color: #f9b000 !important;
        }
    }

    .drag-handle {
        cursor: ns-resize;
    }
</style>
